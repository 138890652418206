<template>
  <div class="mb-3">
    <div class="d-block position-relative">
      <v-row class="justify-center">
        <h3 class="mb-5 text-center">
          {{ $t("examination.refactornoAndVSA") }}
        </h3>
      </v-row>
      <v-sheet elevation="0">
        <v-row justify="space-between">
          <v-col cols="9">
            <div class="d-flex flex-column pl-1 pr-1" style="overflow: auto">
              <div
                class="d-block ma-1"
                v-for="(item, index) in listPreliminary"
                :key="index"
                :id="item.href"
              >
                <div class="preliminaryTest__title">
                  {{ item.name }}
                </div>
                <div class="preliminaryTest__content">
                  <component
                    :is="item.components"
                    :dataPoints="dataPoints"
                    :pathPrefix="pathPrefix"
                  ></component>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3" class="position-relative">
            <div class="position-fixed preliminaryControl__container">
              <v-card elevation="0" outlined>
                <v-card-text class="pa-0">
                  <v-list flat class="pa-0">
                    <v-list-item-group v-model="selectTest">
                      <v-list-item
                        active-class="preliminaryControl__item__active"
                        v-for="(item, index) in listPreliminary"
                        :key="index"
                      >
                        <v-list-item-content
                          v-scroll-to="{
                            element: `#${item.href}`,
                            offset: -200,
                          }"
                        >
                          {{ item.name }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </div>
    <v-bottom-navigation
      grow
      height="50"
      absolute
      class="elevation-0 exam-bottom-controller"
      hide-on-scroll
    >
      <v-btn
        class="pl-10 pr-10 white--text"
        color="#0065FF"
        @click="saveDataPointsAndCompleteOrderItem"
        v-bind:disabled="isFinishExamination === true ? true : false"
      >
        {{ $t("common.SaveData") }}
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import DataPointService from "@/services/dataPoint";
import i18n from "@/plugins/i18n";

export default {
  name: "PreliminaryComponent",
  props: {
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pathPrefix: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    selectTest: 0,
    isFinishExamination: false,
    orderItemValidation: {
      isAllowCancel: false,
      staffPerformName: "",
      staffOrderName: "",
    },
    listPreliminary: [
      {
        name: "Tonometer & PD",
        href: "tonometerPD",
        components: "TOPDComponent",
      },
      {
        name: "Visual Accuity",
        href: "visualAccuity",
        components: "VSAComponent",
      },
    ],
  }),
  async created() {
    await this.getEpisodeRecordDataPoints();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    async saveDataPointsAndCompleteOrderItem() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var patientID = this.$route.params.patientId;

      var dataPoints = this.dataPoints;
      // update collection data-points
      await DataPointService.postCollection(
        episodeRecordID,
        patientID,
        dataPoints
      );
      // complete the order with status = 3
      this.showSuccess(i18n.t("message.examination.saveDatapoint"));
    },
    async getEpisodeRecordDataPoints() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      if (!episodeRecordID) return;
      var result = await DataPointService.searchDataPoint(episodeRecordID);
      if (!result || result.error) {
        this.showError(
          "Cannot get form data-point at the moment! Please try again later."
        );
        return;
      }
      this.dataPoints = JSON.parse(JSON.stringify(result.items));
      console.log(this.dataPoints);
    },
  },
};
</script>

<style scoped lang="scss">
.exam-bottom-controller {
  background-color: #f5f5f5 !important;
  button {
    background-color: #0277bd !important;
    .v-btn__content {
      color: white !important;
      font-size: 120%;
      text-transform: uppercase;
    }
  }
  button:disabled {
    background-color: #bdbdbd !important;
    .v-btn__content {
      color: #424242 !important;
    }
  }
}
.v-item-group.v-bottom-navigation {
  display: flex;
  width: calc(100% - 16px) !important;
  bottom: 15px;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.position-fixed {
  position: fixed;
}
.preliminaryTest__title {
  display: block;
  width: 100%;
  padding: 10px 12px;
  background-color: #03214d;
  color: #ffffff;
  font-size: 18px;
  font-weight: 450;
}
.preliminaryTest__content {
  display: block;
  padding: 10px 0px;
}
.preliminaryControl__container {
  display: inline-block;
  width: 250px;

  .preliminaryControl__item__active {
    color: #ffffff;
    background-color: #0d0f12;
    font-size: 14px;
  }
}
</style>